<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEditLocation" />
    <feather-icon v-if="!this.params.data.jsonData" title="Add Sub-locations" icon="ListIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEditSubLocations" />
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showDeletePopup=true" />
    
    <vs-prompt
      title="Delete Location"
      accept-text="Delete"
      @accept="onDeleteLocation"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete {{params.data.locationName}}? This will delete locations associated with this location.</div>
          </div>
        </div>
    </vs-prompt>

  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onEditSubLocations () {
      this.params.context.componentParent.editLocationList(this.params.data.locationId) 
    },
    onEditLocation () {
      this.params.context.componentParent.editLocationDetails(this.params.data.locationId) 
    },
    onDeleteLocation () {
      this.params.context.componentParent.deleteLocation(this.params.data.locationId) 
    }
  }
}
</script>
