<template>
  <travio-modal width='450' height="80" zIndex="99999">
    <h3 slot="header">{{title}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Location Name</label>
          <vs-input class="w-full" v-model="locationState.locationName" v-validate="'required|max:100'" name="Location Name" />
          <span class="text-danger text-sm">{{ errors.first('Location Name') }}</span>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveLocation" type="filled">Save</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      locationState: {}
    }
  },
  computed: {
    title () {
      return this.locationState.locationId ? 'Edit Location Name' : 'Add Parent Location'
    }
  },
  props: {
    applicationId: { required: true }, 
    location: { type: Object, required: true }
  },
  mounted () {
    this.locationState = JSON.parse(JSON.stringify(this.location))
  },
  methods: {
    async saveLocation () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      let emitter = null
      let promise = null

      if(this.locationState.locationId) {
        emitter = 'onEditSuccess'
        promise = this.$http.put(`api/locationLists/apps/${this.applicationId}/${this.location.locationListId}`, this.locationState)
      } else {
        emitter = 'onAddSuccess'
        promise = this.$http.post(`api/locationLists/apps/${this.applicationId}/${this.location.locationListId}`, this.locationState)
      }

      try {
        let response = await promise
        this.$_notifySuccess('Successfully saved location data');

        if(!this.locationState.locationId){
          this.locationState.locationId= response.data
        }
        this.$emit( emitter, Object.assign(this.locationState) )
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  

    },
    close () {
      this.showLocationNameEditor=false
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>