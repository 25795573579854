<template>
  <travio-center-container grid-width='2/3'>
    <h2 class="mb-3" style="color:#636363"><span class="text-muted">Edit Location List</span></h2>
    <vx-card v-show="locationListName">
      <div class="flex flex-wrap justify-start mb-3">
        <h2 style="color:#636363">{{locationListName}}</h2>
        <vs-button  size="small" class="ml-3" type="border" color="success" @click="editListLocationName">Rename</vs-button>
      </div>
      <vs-breadcrumb :separator="'chevron_right'" :items="menuItems">
        <li v-for="(menuItem, index) in menuItems" :key="index">
          <router-link class="vs-breadcrumb--link" v-if="!menuItem.active" :to="menuItem.url">{{menuItem.title}}</router-link>
          <span v-if="menuItem.active" class="vs-breadcrumb--text vs-breadcrumb-text-primary">{{menuItem.title}}</span>
          <span v-if="!menuItem.active" translate="translate" aria-hidden="true" class="separator notranslate vs-breadcrum--separator material-icons">
            chevron_right
          </span>
        </li>
      </vs-breadcrumb>
      <div class="flex flex-wrap justify-start mt-3">
        <!-- <vs-button color="primary" @click="backToParent" type="filled">{{backButtonLabel}}</vs-button>       -->
        <vs-button @click="addLocation" v-if="!isLastLevel" class="sm:mb-0 mb-2">Add Parent Location</vs-button>
        <vs-button @click="addLocationByAutoComplete" v-if="!isFirstLevel" class="ml-2 sm:mb-0 mb-2">Add Searchable Location</vs-button>
      </div>
      
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="locationList"
        :gridOptions="gridOptions"
        :rowDragManaged="true"
        :animateRows="true"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
        @first-data-rendered="onFirstDataRendered"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
      
    </vx-card>
    <location-editor 
      v-if="showLocationEditor"
      :applicationId="applicationId"
      :location="locationStateForEditor"
      @onAddSuccess="onAddSuccess"
      @onEditSuccess="onEditSuccess"
      @onCancel="showLocationEditor=false" 
    />

    <location-editor-auto-complete 
      v-if="showLocationAutoComplete"
      :applicationId="applicationId"
      :location="locationStateForEditor"
      @onAddSuccess="onAddSuccessAutoComplete"
      @onEditSuccess="onEditSuccessAutoComplete"
      @onCancel="showLocationAutoComplete=false" 
    />

    <location-list-name-editor 
      v-if="showListNameEditor"
      :applicationId="applicationId"
      :locationListId="locationListId"
      :locationListName="locationListName"
      @onEditSuccess="onEditListNameSuccess"
      @onCancel="showListNameEditor=false" 
    />

  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import LocationEditor from './components/LocationEditor.vue'
import LocationEditorAutoComplete from './components/LocationEditorAutoComplete.vue'
import LocationListNameEditor from './components/LocationListNameEditor.vue'

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    LocationEditor,
    LocationEditorAutoComplete,
    LocationListNameEditor
  },
  props: {
    applicationId: { required: true },
    locationListId: { required: true },
    locationId: { default: 0 },
    isLastLevel: { default: false },
    isFirstLevel: { default: false }
  },
  data () {
    return {
      locationList: [],
      locationListName: null,
      parentLocationId: 0,
      parentLocationName: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions
      },
      menuItems: [],
      locationStateForEditor: {},
      showListNameEditor: false,
      showLocationEditor: false,
      showLocationAutoComplete: false,
      context: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    backButtonLabel() {
      return this.parentLocationId == 0 ? 'Back to list' : 'Back to parent'
    }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.$vs.loading()
    
    try {
      const response = await this.$http.get(`api/locationLists/apps/${this.applicationId}/${this.locationListId}/parent/${this.locationId}`)
      this.locationListName = response.data.locationListName
      this.parentLocationId = response.data.parentLocationId
      this.parentLocationName = response.data.parentLocationName
      this.locationList = response.data.locationLists.map(x => Object.assign(x, { isLastLevel: this.isLastLevel}))

      this.menuItems = [
        {
          title: 'List Locations',
          url: `/applications/${this.applicationId}/tools/location-lists`
        },
        {
          title: this.locationListName,
          url: `/applications/${this.applicationId}/tools/location-lists/${this.locationListId}/level1`,
          active: !this.parentLocationName
        },
      ]

      if(response.data.breadCrumbs.length > 0) {
        const level2Parent = response.data.breadCrumbs[0];
        this.menuItems.push({
          title: level2Parent.locationName,
          url: `/applications/${this.applicationId}/tools/location-lists/${this.locationListId}/${level2Parent.locationId}/level2`,
        }) 
      }

      if (this.parentLocationName){
        this.menuItems.push({ title: this.parentLocationName, active: true})
      }

    } catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close();
    

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'locationId', hide: true },
      { field: 'orderId', hide: true },
      { field: 'jsonData', hide: true },
      { headerName: 'Location Name', field: 'locationName', rowDrag: true, width: 300,  suppressSizeToFit: false },
      { headerName: 'Sub-locations', field: 'sublocationCount',  width: 150,  suppressSizeToFit: true },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: false,
      resizable: true,
      suppressMenu: true
    }

    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.locationList, x => x.locationId == rowNodeGettingDragged.data.locationId)
      const toIndex = e.overIndex
      array_move(this.locationList, fromIndex, toIndex)
      
      this.locationList = JSON.parse(JSON.stringify(this.locationList))

      try {
        let response = await this.$http.put(
          `api/locationLists/apps/${this.applicationId}/${this.locationListId}/reorder`,
          { locationOrderItems: this.locationList }
        )

        this.$_notifySuccess('Successfully reordered location');
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()

    }
  },

  methods: {
    addLocation () {
      this.setLocationStateForEdit()
      this.showLocationEditor = true
    },
    addLocationByAutoComplete () {
      this.setLocationStateForEdit()
      this.showLocationAutoComplete = true
    },
    setLocationStateForEdit () {
      let maxOrderId = 0
      
      if(this.locationList && this.locationList.length > 0) {
        maxOrderId = Math.max(...this.locationList.map(x => x.orderId))
      }

      this.locationStateForEditor = {
        locationListId: parseInt(this.locationListId),
        locationId: null,
        locationParentId: parseInt(this.locationId),
        locationName: null,
        orderId: maxOrderId + 1,
        jsonData: null,
        sublocationCount: 0
      }
    },
    onAddSuccess (newState) { 
      this.locationList = [...this.locationList, newState]
      this.showLocationEditor = false
    },
    onEditSuccess (updatedState) {
      const index = this.locationList.findIndex(x => x.locationId == updatedState.locationId)
      this.locationList[index] = updatedState
      this.locationList = [...this.locationList]
      this.showLocationEditor = false
    },
    onAddSuccessAutoComplete (newState) {
      this.locationList = [...this.locationList, newState]
      this.showLocationAutoComplete = false
    },
    onEditSuccessAutoComplete (updatedState) {
      const index = this.locationList.findIndex(x => x.locationId == updatedState.locationId)
      this.locationList[index] = updatedState
      this.locationList = [...this.locationList]
      this.showLocationAutoComplete = false
    },
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    editLocationList(locationId){
      this.$emit('onEditLocation', locationId)
    },
    editLocationDetails (locationId) {
      const itemForEdit = this.locationList.find(x => x.locationId == locationId)

      this.locationStateForEditor = {
        locationListId: parseInt(this.locationListId),
        locationId: itemForEdit.locationId,
        locationParentId: parseInt(this.locationId),
        locationName: itemForEdit.locationName,
        orderId: itemForEdit.orderId,
        jsonData: itemForEdit.jsonData,
        sublocationCount: itemForEdit.sublocationCount
      }
      if(itemForEdit.jsonData) {
        this.showLocationAutoComplete = true
      } else {
        this.showLocationEditor = true
      }
    },
    async deleteLocation (locationId) {
      this.$vs.loading()
      try {
        await this.$http.delete(`api/locationLists/apps/${this.applicationId}/${this.locationListId}/${locationId}`)
        this.$_notifySuccess('Successfully deleted location');
        this.locationList = [...this.locationList.filter(x => x.locationId != locationId)]
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    editListLocationName () {
      this.showListNameEditor = true
    },
    onEditListNameSuccess (updatedName) {
      this.locationListName = updatedName
      const menuItem = this.menuItems.find(x => x.url && x.url.indexOf('/level1')> -1)
      if(menuItem) {
        menuItem.title = updatedName
      }
      this.showListNameEditor = false
    },
    backToParent() {
      this.$emit('onBackToParent', this.parentLocationId)
    }
  }
}
</script>

<style>

</style>